<template>

  <div>
    <b-row
      class="content-header"
      style="flex-wrap: nowrap;"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              Annual Grouping
            </h2>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right mb-1"
        md="3"
      >
        <b-button
          v-if="canViewThisAction('create', 'RosterYear')"
          variant="success"
          :to="{ name: 'operation-roster-annual-groupings-create' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle d-md-block d-none">Create New Year</span>
          <span class="align-middle d-md-none d-block">New</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 roster__grouping annual__groupings"
      no-body
    >
      <div class="">

        <!-- Table Top -->
        <div class="group__top">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <div class="demo-inline-spacing roster_group_dropdown">
                <!-- primary -->
                <b-dropdown
                  id="dropdown-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :text="selectedRosterGroup"
                  variant="outline-primary"
                >
                  <b-dropdown-text style="width: 240px;">
                    Designated day off for roster groups
                  </b-dropdown-text>
                  <b-dropdown-item
                    v-for="(option, key) in rosterGroups"
                    :key="key"
                  >
                    <div>
                      <span>{{ option.name }} ({{ option.dayOffDaysNumber }})</span>
                      <div class="text-nowrap">
                        <span
                          v-for="(day, index) in option.dayOffDays"
                          :key="index"
                        >
                          {{ day }} <span v-if="index + 1 != option.dayOffDays.length">, </span>
                        </span>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-text>
                    <b-button
                      v-if="canViewThisAction('create', 'RosterGroup')"
                      variant="primary"
                      :to="{ name: 'operation-roster-groups-create' }"
                    >
                      Add Roster Group
                    </b-button>
                  </b-dropdown-text>
                </b-dropdown>
              </div>
              <div class="demo-inline-spacing">
                <!-- primary -->
                <b-dropdown
                  id="dropdown-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :text="selectedYear"
                  variant="primary"
                >
                  <b-dropdown-item
                    v-for="(option, key) in yearValues"
                    :key="key"
                    @click="changeAnnualGrouping(option.name)"
                  >
                    {{ option.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <div class="search_block d-flex align-items-center justify-content-end">
                  <a
                    href="javascript:void(0)"
                    class="nav-link nav-link-search"
                    @click="showSearchBar = !showSearchBar"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="21"
                      class="mr-50"
                    />
                    <span class="align-middle">Search</span>
                  </a>
                  <b-input-group
                    v-if="showSearchBar"
                    class="input-group-merge mr-50"
                  >
                    <b-form-input
                      v-model="searchQuery"
                      placeholder="Search..."
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="searchQuery = ''; showSearchBar = !showSearchBar"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <template>
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreHorizontalIcon"
                        size="16"
                        class="align-middle text-body"
                      /> More
                    </template>

                    <b-dropdown-item
                      v-if="canViewThisAction('create', 'RosterYear')"
                      :to="{ name: 'operation-roster-annual-groupings-create', query: { clone: resolveYearID() } }"
                    >
                      <span class="align-middle ml-50">Duplicate This Year</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="canViewThisAction('create', 'Team')"
                      id="toggle-btn"
                      v-b-modal.modal-create-team
                      @click="makeTeamEmpty"
                    >
                      <span class="align-middle ml-50">Add a New Team</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="canViewThisAction('update', 'RosterYear')"
                      :to="{ name: 'operation-roster-annual-groupings-edit', params: { id: resolveYearID() } }"
                    >
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="canViewThisAction('delete', 'RosterYear')"
                      @click="deleteEntity(resolveYearID())"
                    >
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="group__bottom">
          <b-table-simple
            ref="teamsTable"
            sticky-header="500px"
            class="position-relative has_padding"
            responsive
            hover
            caption-top
          >
            <b-thead head-variant="light">
              <b-tr class="caption_block">
                <th colspan="14">
                  <caption>Annual grouping for Year {{ selectedYear }}</caption>
                </th>
              </b-tr>
              <b-tr>
                <b-th width="16%">
                  Staff Name
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Jan
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Feb
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Mar
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Apr
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  May
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Jun
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Jul
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Aug
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Sep
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Oct
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Nov
                </b-th>
                <b-th
                  width="7.14%"
                  class="text-left"
                >
                  Dec
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(team, key) in searchUser"
                :key="key"
              >
                <b-td
                  v-if="team.users.length"
                  class="no-padding-td"
                  colspan="13"
                >
                  <b-table-simple
                    ref="relatedUserTable"
                    class="position-relative"
                    responsive
                    hover
                  >
                    <b-thead>
                      <b-tr>
                        <b-th colspan="10">
                          {{ team.name }}
                        </b-th>
                        <b-th
                          colspan="3"
                          class="text-right"
                        >
                          <div class="action_button">
                            <b-button
                              v-if="canViewThisAction('update', 'Team')"
                              variant="flat-primary"
                              @click="updateTeamModal(team)"
                            >
                              <feather-icon
                                icon="EditIcon"
                                class="mr-50"
                                size="16"
                              />
                            </b-button>
                            <b-button
                              v-if="canViewThisAction('update', 'RosterYear')"
                              v-show="key != 0"
                              variant="flat-primary"
                              @click="moveTeam(key, key-1)"
                            >
                              <feather-icon
                                icon="ArrowUpIcon"
                                class="mr-50"
                                size="16"
                              />
                            </b-button>
                            <b-button
                              v-if="canViewThisAction('update', 'RosterYear')"
                              v-show="key != (teams.length - 1)"
                              variant="flat-primary"
                              @click="moveTeam(key, key+1)"
                            >
                              <feather-icon
                                icon="ArrowDownIcon"
                                class=""
                                size="16"
                              />
                            </b-button>
                          </div>
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody class="white-body-background">
                      <b-tr
                        v-for="(user, index) in team.users"
                        :key="index"
                      >
                        <b-td width="16%">
                          {{ user.name }}
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          {{ resolveGroupName(user._id, 'jan') }}
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          {{ resolveGroupName(user._id, 'feb') }}
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          {{ resolveGroupName(user._id, 'mar') }}
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          {{ resolveGroupName(user._id, 'apr') }}
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          {{ resolveGroupName(user._id, 'may') }}
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          {{ resolveGroupName(user._id, 'jun') }}
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          {{ resolveGroupName(user._id, 'jul') }}
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          {{ resolveGroupName(user._id, 'aug') }}
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          {{ resolveGroupName(user._id, 'sep') }}
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          {{ resolveGroupName(user._id, 'oct') }}
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          {{ resolveGroupName(user._id, 'nov') }}
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          {{ resolveGroupName(user._id, 'dec') }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </b-card>
    <!-- modal -->
    <b-modal
      id="modal-create-team"
      ref="create-team-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Save Changes"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Add A Team"
      @ok="submitTeamForm"
    >
      <b-form @submit.prevent="submitTeamForm">
        <validation-observer
          ref="createTeamForm"
        >
          <b-form-group
            label="Team Name*"
            label-for="h-team-name"
          >
            <validation-provider
              #default="{ errors }"
              name="Team Name"
              vid="name"
              rules="required"
            >
              <b-form-input
                id="h-team-name"
                v-model="name"
                placeholder="Enter Team Name"
                name="name"
                @input="nameValidation == true ? nameValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="nameValidation"
                class="text-danger"
              >
                {{ nameError }}
              </small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Team Member*"
            label-for="h-team-users"
          >
            <validation-provider
              name="Team Member"
              vid="users"
              rules="required"
            >
              <b-form-tags
                v-model="users"
                size="lg"
                add-on-change
                no-outer-focus
              >
                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                  <ul
                    v-if="tags.length > 0"
                    class="list-inline d-inline-block mb-1"
                  >
                    <li
                      v-for="tag in tags"
                      :key="tag"
                      class="list-inline-item"
                    >
                      <b-form-tag
                        :title="tag"
                        :disabled="disabled"
                        variant="danger"
                        @remove="removeTag(tag)"
                      >
                        {{ resolveUserName(tag) }}
                      </b-form-tag>
                    </li>
                  </ul>
                  <b-form-select
                    v-bind="inputAttrs"
                    v-on="inputHandlers"
                  >
                    <template v-slot:first>
                      <!-- This is required to prevent bugs with Safari -->
                      <option
                        disabled
                        value=""
                      >
                        Choose a User....
                      </option>
                    </template>
                    <option
                      v-for="(user, ind) in userOptions"
                      :key="ind"
                      :value="user._id"
                    >
                      {{ user.name }}
                    </option>
                  </b-form-select>
                </template>
              </b-form-tags>
            </validation-provider>
            <small
              v-if="userValidation"
              class="text-danger"
            >
              {{ userError }}
            </small>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-update-team"
      ref="update-team-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Save Changes"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      :title="'Edit ' + selectedTeam.name"
      @ok="updateTeamForm"
    >
      <b-form @submit.prevent="updateTeamForm">
        <validation-observer
          ref="teamUpdateForm"
        >
          <b-form-group
            label="Team Name*"
            label-for="h-team-name"
          >
            <validation-provider
              #default="{ errors }"
              name="Team Name"
              vid="name"
              rules="required"
            >
              <b-form-input
                id="h-team-name"
                v-model="name"
                placeholder="Enter team name"
                name="name"
                @input="nameValidation == true ? nameValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="nameValidation"
                class="text-danger"
              >
                {{ nameError }}
              </small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Team Member*"
            label-for="h-team-users"
          >
            <validation-provider
              name="Team Member"
              vid="users"
              rules="required"
            >
              <b-form-tags
                v-model="users"
                size="lg"
                add-on-change
                no-outer-focus
              >
                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                  <ul
                    v-if="tags.length > 0"
                    class="list-inline d-inline-block mb-1"
                  >
                    <li
                      v-for="tag in tags"
                      :key="tag"
                      class="list-inline-item"
                    >
                      <b-form-tag
                        :title="tag"
                        :disabled="disabled"
                        variant="danger"
                        @remove="removeTag(tag)"
                      >
                        {{ resolveUserName(tag) }}
                      </b-form-tag>
                    </li>
                  </ul>
                  <b-form-select
                    v-bind="inputAttrs"
                    v-on="inputHandlers"
                  >
                    <template v-slot:first>
                      <!-- This is required to prevent bugs with Safari -->
                      <option
                        disabled
                        value=""
                      >
                        Choose a User....
                      </option>
                    </template>
                    <option
                      v-for="(user, ind) in userOptions"
                      :key="ind"
                      :value="user._id"
                    >
                      {{ user.name }}
                    </option>
                  </b-form-select>
                </template>
              </b-form-tags>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-danger"
          class="mr-auto pl-0 ml-0 no-background"
          @click="deleteTeam()"
        >
          Delete
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="success"
          @click="ok()"
        >
          <span class="align-middle">Save Changes</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BButton, BDropdown, BDropdownItem, BFormInput, BInputGroup, BInputGroupAppend, BDropdownText,
  BTableSimple, BThead, BTbody, BTr, BTh, BTd, BModal, BForm, BFormGroup, BFormTags, BFormTag, BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import moment from 'moment'
import 'array.prototype.move'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BInputGroupAppend,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BDropdown,
    BDropdownItem,
    BModal,
    BForm,
    BFormGroup,
    BFormTags,
    BFormTag,
    BDropdownText,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      canViewThisAction,
      showSearchBar: false,
      searchQuery: '',
      selectedYear: '0000',
      yearValues: [],
      annualGroupingValues: [],
      teams: [],
      rosterGroups: [],
      callLog: {},
      callLogID: '',
      stringID: '',
      detachedStringID: '',
      dutyOfficer: '',
      dutyOfficerOptions: [],
      dutyOfficerValidation: false,
      dutyOfficerError: 'Valid duty officer is required',
      relatedRecords: [],
      name: '',
      nameValidation: false,
      nameError: 'Valid name is required',
      userOptions: [],
      userError: 'Valid user is required',
      userValidation: false,
      users: [],
      status: 'active',
      selectedRosterGroup: 'Roster Groups',
      selectedTeam: '',
      teamId: '',
      tableColumns: [
        {
          key: 'stringID',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'booking',
          label: 'Record Name',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'created date',
          sortable: true,
        },
        {
          key: 'createdBy.name',
          label: 'created by',
          sortable: true,
        },
        { key: 'actions' },
      ],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo.svg'),

      required,
    }
  },
  computed: {
    searchUser() {
      return this.teams.map(team => {
        const data = { ...team }
        data.users = data.users.filter(user => user.name.toLowerCase().match(this.searchQuery.toLowerCase()))
        return data
      })
    },
  },
  beforeMount() {
    this.$http.get('directory/teams/respond-with/user-options')
      .then(response => {
        this.userOptions = response.data.userOptions ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  created() {
    this.$http.get('operation/roster-annual-groupings',
      {
        params: {
          // search: searchQuery.value,
          sortBy: 'name',
          sortDesc: false,
        },
      })
      .then(response => {
        this.yearValues = response.data.yearValues || []
        this.annualGroupingValues = response.data.annualGroupingValues || []
        this.teams = response.data.teams || []
        this.rosterGroups = response.data.rosterGroups || []

        if (this.yearValues.length) {
          const timezone = process.env.VUE_APP_TIME_ZONE
          // eslint-disable-next-line eqeqeq
          let currentYear = this.yearValues.find(o => o.name == moment().utcOffset(timezone).year())
          if (!currentYear) {
            // eslint-disable-next-line prefer-destructuring
            currentYear = this.yearValues[0]
          }
          this.selectedYear = currentYear.name

          this.teams.sort((a, b) => currentYear.teamPriority.indexOf(a._id) - currentYear.teamPriority.indexOf(b._id))
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    resolveUserName(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.name
      }
      return ''
    },
    getUserOptions() {
      this.$http.get(`directory/teams/respond-with/user-options/${this.teamId}`)
        .then(response => {
          this.userOptions = response.data.userOptions ?? []
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateTeamModal(team) {
      this.selectedTeam = team

      this.teamId = this.selectedTeam._id
      this.getUserOptions()
      this.name = team.name
      this.status = team.status
      this.users = []
      if (team.users.length) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < team.users.length; i++) {
          this.users.push(team.users[i]._id)
        }
      }
      this.nameValidation = false
      this.userValidation = false
      this.$refs['update-team-modal'].toggle('#toggle-btn')
    },
    makeTeamEmpty() {
      this.name = ''
      this.users = []
      this.nameValidation = false
      this.userValidation = false
      this.$http.get('directory/teams/respond-with/user-options')
        .then(response => {
          this.userOptions = response.data.userOptions ?? []
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Are You Sure?',
        html: `Deleting <strong>will erase all the information</strong> for ${this.selectedYear}. This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.delete(`operation/roster-years/${id}/delete`)
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message ?? '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
                this.yearValues = response.data.yearValues || []
                this.annualGroupingValues = response.data.annualGroupingValues || []
                this.teams = response.data.teams || []
                this.rosterGroups = response.data.rosterGroups || []

                if (this.yearValues.length) {
                  const timezone = process.env.VUE_APP_TIME_ZONE
                  // eslint-disable-next-line eqeqeq
                  let currentYear = this.yearValues.find(o => o.name == moment().utcOffset(timezone).year())
                  if (!currentYear) {
                    // eslint-disable-next-line prefer-destructuring
                    currentYear = this.yearValues[0]
                  }
                  this.selectedYear = currentYear.name

                  this.teams.sort((a, b) => currentYear.teamPriority.indexOf(a._id) - currentYear.teamPriority.indexOf(b._id))
                } else {
                  this.yearValues = []
                  // this.teams = []
                  this.selectedYear = '0000'
                }
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    changeRosterGrouping(group) {
      this.selectedRosterGroup = group
    },
    changeAnnualGrouping(year) {
      this.$http.get('operation/roster-annual-groupings',
        {
          params: {
            // search: searchQuery.value,
            year,
            sortBy: 'name',
            sortDesc: false,
          },
        })
        .then(response => {
          this.teams = response.data.teams || []
          this.annualGroupingValues = response.data.annualGroupingValues || []

          this.selectedYear = year

          const currentYear = this.yearValues.find(o => o.name === year)
          if (currentYear) {
            this.teams.sort((a, b) => currentYear.teamPriority.indexOf(a._id) - currentYear.teamPriority.indexOf(b._id))
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    // eslint-disable-next-line no-unused-vars
    resolveGroupName(user, month) {
      const group = this.annualGroupingValues.find(o => o.user === user)
      if (group) {
        const val = this.rosterGroups.find(o => o._id === group[month])
        if (val) {
          return val.abbreviation
        }
      }

      return 'N/A'
    },

    resolveYearID() {
      const year = this.yearValues.find(o => o.name === this.selectedYear)
      if (year) {
        return year._id
      }

      return '0'
    },
    updateTeamForm() {
      this.$refs.teamUpdateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('name', this.name)
          formData.append('status', this.status)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.users.length; i++) {
            formData.append('users[]', this.users[i])
          }
          this.$http.patch(`directory/teams/${this.teamId}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.$swal({
                title: 'Team Updated!',
                html: `The team for <strong>${this.name}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then(result => {
                  this.name = ''
                  this.status = 'active'
                  this.users = []
                  if (result.value) {
                    this.changeAnnualGrouping(this.selectedYear)
                  }
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'users') {
                    this.userError = validationError.msg
                    this.userValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })

                this.$refs['update-team-modal'].toggle('#toggle-btn')

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        } else {
          if (this.name === '') {
            this.nameError = 'Name is Required'
            this.nameValidation = true
          } else {
            this.nameValidation = false
          }
          if (this.users.length === 0) {
            this.userError = 'User is Required'
            this.userValidation = true
          } else {
            this.userValidation = false
          }
          this.$refs['update-team-modal'].toggle('#toggle-btn')
        }
      })
    },
    deleteTeam() {
      this.$swal({
        title: 'Are You Sure?',
        html: `Deleting <strong>will erase all the information</strong> for ${this.name}. This action can not be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$refs['update-team-modal'].toggle('#toggle-btn')
            this.$http.delete(`directory/teams/${this.teamId}/delete`)
              .then(() => {
                this.$swal({
                  title: 'Team Deleted!',
                  html: `The team <strong>${this.name}</strong> has been deleted`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(confirmation => {
                    this.name = ''
                    this.status = 'active'
                    this.users = []
                    if (confirmation.value) {
                      this.changeAnnualGrouping(this.selectedYear)
                    }
                  })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    submitTeamForm() {
      this.$refs.createTeamForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('name', this.name)
          formData.append('status', this.status)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.users.length; i++) {
            formData.append('users[]', this.users[i])
          }
          this.$http.post('directory/teams/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.$swal({
                title: 'Team Created!',
                html: `The team for <strong>${this.name}</strong> has been created`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then(result => {
                  this.name = ''
                  this.status = 'active'
                  this.users = []
                  if (result.value) {
                    this.changeAnnualGrouping(this.selectedYear)
                  }
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'users') {
                    this.userError = validationError.msg
                    this.userValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })

                this.$refs['create-team-modal'].toggle('#toggle-btn')

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        } else {
          if (this.name === '') {
            this.nameError = 'Name is Required'
            this.nameValidation = true
          } else {
            this.nameValidation = false
          }
          if (this.users.length === 0) {
            this.userError = 'User is Required'
            this.userValidation = true
          } else {
            this.userValidation = false
          }
          this.$refs['create-team-modal'].toggle('#toggle-btn')
        }
      })
    },
    moveTeam(from, to) {
      this.teams.move(from, to)

      const teamPriorityArray = []
      this.teams.forEach(team => {
        teamPriorityArray.push(team._id)
      })
      const formData = new FormData()

      formData.append('name', this.selectedYear)
      formData.append('teamPriority', JSON.stringify(teamPriorityArray))

      this.$http.patch('operation/roster-annual-groupings/update/team-priority', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  table td.no-padding-td {
    padding: 12px 0px !important
  }
  table td.td-no-padding-right {
    padding: 12px 8px !important
  }
  .white-body-background {
    background-color: white;
  }
  .v-select {
    width: 95px;
  }

  .b-table-sticky-header > .table.b-table > thead > tr > th {
      position: sticky;
      top: 38px;
      z-index: 2;
  }
  .roster__grouping .group__bottom caption {
      position: sticky;
      top: 0;
      z-index: 2;
  }
</style>
